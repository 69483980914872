import React from 'react'

const Footer = () => (
  <div id="footer">
    <div className="inner">
      <ul className="icons">
        <li>
          <a href="https://www.instagram.com/ffynnonfelen/" target="_blank" className="icon fa-instagram">
            <span className="label">Instagram</span>
          </a>
        </li>
        <li>
          <a href="https://www.facebook.com/pg/ffynnonfelen" target="_blank" className="icon fa-facebook">
            <span className="label">Facebook</span>
          </a>
        </li>
        <li>
          <a href="mailto: hello@ffynnonfelen.co.uk" className="icon fa-envelope-o">
            <span className="label">Email</span>
          </a>
        </li>
      </ul>
      <ul className="copyright">
        <li>&copy; 2020 Kura Digital Ltd</li>
        <li>Ffynnon Felen</li>
      </ul>
    </div>
  </div>
)

export default Footer
