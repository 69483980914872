import React from 'react'
import {Link} from 'gatsby'
import Footer from './Footer'
import logo from '../assets/images/logo_header.svg'


function Header() {
    return (
    <header id="header">
      <div className="inner">
        <Link to="/" className="image avatar">
            <img src={logo} alt="" />
        </Link>
        <h1>
          <center>
            <strong>A beautiful period farmhouse set at the top of a stunning Welsh Valley in the heart of West Wales.</strong>
          </center>
        </h1>
      </div>
      <Footer />
    </header>
  )
}

export default Header
